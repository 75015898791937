@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiContainer-maxWidthLg {
  max-width: 1620px !important;
}

p{
  line-height: 30px;
  letter-spacing: 1px;
}

h1{
  font-size: 60px;
  color: #080F58;
  font-weight: 400;
}

.container .MuiContainer-maxWidthLg {
  max-width: 1160px !important;
  position: relative;
}
.MuiPaper-elevation1{
  box-shadow:none !important;
}

.button_green_fill {
  color: #fff !important;
  background-color: #00C9A8 !important;
}

.button_yellow_fill {
  color: #fff !important;
  background-color: #FEC553 !important;
}

.button_green_outline{
  color: #00C9A8 !important;
  background: none !important;
  border: #00C9A8 2px solid !important;
}

.button {
  margin: 15px !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
  padding: 15px 40px !important;
  font-weight: 900 !important;
}

.button_section{
  margin-left: -15px;
}

.responsive_img img{
  width: 100%;
}



.heaing_section h1{
  font-size: 34px;
  font-family:typeface-roboto-slab;

}

.heaing_section p{
  font-size: 20px;

}

.heaing_section.white h1{
 color: #fff; 

}

.white_text{
  color: #fff !important; 
 }
 
.center{
  text-align: center;
}

.border_line_grey{
  background:#e5e5e5;
  height: 1.5px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.TopbarContainer{
    background: #000000;
    height: 34px;
    display: flex;
}

.HeaderOneMainContainer {
    position: relative  !important;

}

.menu-item a{ font-family: 'Poppins', sans-serif !important;}
.MuiButton-label{font-family: 'Poppins', sans-serif !important;}


.HeaderFixed .HeaderOneMainContainer {
    position: fixed !important

}

.TopbarContainer .TopbarContainerInner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TopbarContainer .TopbarContainerInner .HeaderTopLeftCol{
    display: flex;
    align-items: center;
}

.TopbarContainer .TopbarContainerInner .HeaderTopLeftCol a{
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px;
    margin-right: 15px;
}

.TopbarContainer .TopbarContainerInner .HeaderTopLeftCol a span{
    font-weight: 600;
}

.TopbarContainer .TopbarContainerInner .HeaderTopLeftCol svg{
    color: #fff;
    margin-right: 10px;
    font-size: 18px;
}


.sectionSpace{
    padding-top: 145px;
}

.HeaderOnesiteLogo a{
    max-width: 308px !important;
}
html {
	scroll-behavior: smooth;
}

body {font-family: 'Poppins', sans-serif !important;}


.MuiContainer-maxWidthLg {
    max-width: 1227px !important;
}


.TitleLarge{
  font-size: 48px !important;
  letter-spacing: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  line-height: 76px !important;

}

.HomebannerContainer .bannerTitile{
  font-size: 60px !important;
}

.TitleSmall{
  font-size: 48px !important;
  letter-spacing: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  line-height: 56px !important;
}

.TitleGreen{
  font-size: 20px !important;
  letter-spacing: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin-bottom: 12px !important;
  color: #2E4BF2 !important;

}

.SubTitle{
  font-size: 24px !important;
  letter-spacing: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
  line-height: 38px !important;
  margin-top: 20px !important;
  margin-bottom:40px !important;
}

.SubTitle a{
  color: #2E4BF2;
  text-decoration: underline;
  cursor: pointer;
}

.Description{
  font-size: 16px !important;
  letter-spacing: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  margin-top: 16px !important;
  color: #676C7B !important;
}

.priceOff{
  position: relative;
}

.priceOff img{
  position: absolute;
  width: 200px;
}

.date_time_container .MuiInputBase-input{
  margin-left: 17px;
}

@media (max-width: 1015px){
  .priceOff{
    position: relative;
    display: block;
  }

  .priceOff img{
    width: 150px;
  }
}

@media (max-width: 631px){
  .priceOff{
    position: relative;
    display: inline-block;
  }

  .priceOff img{
    width: 150px;
  }
}



.WhiteText{
  color: #fff;
}

.DarkteText{
  color: #0B132B;
}
.GreenText{
  color: #2E4BF2 !important;
}

.MainSection{
  padding: 80px 0;
}

.GreenBG{
  background: #2E4BF2 url(/static/media/Repeat_Grid.4d79bd86.png);
  position: relative;
}

.GreyBG{
  background: #FCFCFC;
}

.masjidImg {
  position: absolute;
  right: 0;
  bottom: -7px;
}

.masjidImg img{
  width: 400px;
}

.AlignCenter{
  text-align: center;
}

.FullWidthGridContainer{
  display: flex;
}

.ButtonGreenFilled{
  background: #2E4BF2 !important;
  color:#fff !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 0 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  padding: 16px !important;
  padding: 16px 32px !important;
}

.ButtonGreenFilled img{
  margin-left: 4px;
}

.ButtonWhiteFilled{
  background: #fff !important;
  color:#2E4BF2 !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 0 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  padding: 16px 32px !important;
}

.ButtonWhiteFilled img{
  margin-left: 4px;
}

input{
  font-family: 'Poppins', sans-serif !important;
}

.Card{
  background: #fff;
  box-shadow: 0px 2px 4px #00000017;
  position: relative;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
  display: block;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif !important;
}

.Card:hover{
  background: #F2F4FF;
  box-shadow: 0px 2px 13px #00000017;
  text-decoration: none !important;
  color: #2E4BF2 !important;
}

.Card h1{
  transition: 0.3s;
}

.Card:hover h1{
  color: #2E4BF2 !important;
}
.Card:hover h2{
  color: #2E4BF2 !important;
}
.Card:hover p{
  color: #2E4BF2 !important;
}

.Card:hover a{
  color: #2E4BF2 !important;
}
.Card:hover a svg{
  color: #2E4BF2 !important;
}


.buttonArrow{
  margin-left: 4px;
}

.buttonCenter{
  display: flex;
  justify-content: center;
  margin-top: 47px;
}

.bottomSpace{
  margin-bottom: 40px !important;
}

.HeaderContainer{
  box-shadow: 0 4px 2px -2px #0000000f;
}

/* ======Globle Css End====== */

.ExpansionHeaderContainer{
  background: #fff;
  padding: 15px 0px;
  box-shadow: 0 4px 2px -2px #0000000f;
}

.GetStartForm{
  display: flex;
  justify-content: center;
  max-width: 550px;
  margin: 0 auto;
  padding-top: 32px;
}

.GetStartedField{
  width: 70%;
}

.GetStartForm .GetStartedField .MuiInputBase-formControl{
  border-radius: 4px 0px 0px 4px;
}

.GetStartForm .GetStartedField .MuiOutlinedInput-notchedOutline {
  border-color: #FFFFFF;
}

.GetStartForm .GetStartedField .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #FFFFFF;
  border-width: 1px;
}

.GetStartForm .GetStartedField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #FFFFFF;
}

.GetStartForm .GetStartedField  .MuiOutlinedInput-input {
  padding: 15.5px 14px;
  color: #fff;
}


.GetStartForm .GetStartedButton{
  background: #FFF;
  color:#2E4BF2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 0;
  border-radius: 0 4px 4px 0;
  box-shadow: none;
  min-width: 163px;
}

/* ====== Banner Start ====== */



/* ======WhatSpecial Css Start====== */

.websiteImg{
  width: 50%;
  margin-right: 50px;
}

.websiteImg img{
  width: 100%;
}

.WebsiteContents{
  width: 30%;
}

.SrList{
  margin-top: 32px;
  margin-bottom: 40px;
}

.SrList li{
  display: flex;
  font-size: 20px !important;
  letter-spacing: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin-bottom: 24px;
  align-items: end;
}

.SrList li img{
  margin-right:16px !important;
}

/* ======WhatSpecial Css End====== */



/* ======BookingForm Css Start====== */
.BookingFormHome{
  display: flex;
}
.BookingFormHome .MuiFormControl-root{
  flex: 1 1;
  margin: 0 8px;
}

.BookingFormHome .ButtonWhiteFilled{
  margin-left: 8px;
}

.BookingFormHome .MuiFormHelperText-contained{
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
}




/* ======BookingForm Css End====== */


/* ======Embed anything Css Start====== */

.BottomContents{
  margin-top: 32px;
}

.DonationBlog .Description{
  margin-top: 0 !important;
}

.DonationBlog .DonatoinFormTitle{
  font-size: 20px !important;
  letter-spacing: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: #0B132B !important;
  display: flex;
  align-items: center;
}

.DonationBlog .DonatoinFormTitle img {
  background: #eef7ec;
  width: 25px;
  border-radius: 100px;
  padding: 8px;
  height: 25px;
  margin-right: 16px;
}

.MuiAccordionSummary-root {
  display: flex !important;
}

.DonationBlog  .MuiAccordion-root.Mui-expanded{
  background:#F2F4FF;
  border-radius: 4px !important;
}

.DonationBlog .MuiAccordionSummary-root.Mui-expanded{
  border: none !important;
}

.DonationBlog .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0;
}

.DonationBlog .MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  padding-left: 59px;
  position: relative;
  top: -10px;
}

.DonationBlog .MuiAccordion-root.Mui-expanded .DonatoinFormTitle img {
  background: #dfefdf;
}

.DonationBlog .MuiAccordion-root:before {
  background-color: transparent;
}

.buildProjectImg{

}

.buildProjectImg img{
  width: 100%;
  border-radius: 10px;
}
/* ======Embed anything Css Start====== */

/* ======BuildProjectPlan Css Start====== */
.BuildProjectBox{
  padding: 30px;
}

.BuildProjectBox .MuiButtonBase-root{
  color: #2E4BF2 !important;
  font-size: 14px;
  font-weight: 700;
  padding: 0 !important;
}

.BuildProjectBox .MuiButtonBase-root:hover{
  background-color: transparent;
}

.BuildProjectBox h1 {
  font-size: 33px;
  font-weight: 700;
  color: #0B132B;
  line-height: 36px;
  font-family: 'Poppins', sans-serif !important;
}

.BuildProjectBox .cardIcon{
  height: 40px;
  margin-bottom: 16px;
}

.BuildProjectBox .cardIcon img{
  height: 40px;
}

.BuildProjectBox .Description{
  min-height: 120px;
}

/* ======BuildProjectPlan Css End====== */


/* ======WebsiteFeatures Css Start====== */
.FeatureList{
  min-height: 144px;
}

.FeatureList h1 {
  font-size: 20px;
  font-weight: 700;
  color: #0B132B;
  line-height: 36px;
  margin: 0;
  position: relative;
  font-family: 'Poppins', sans-serif !important;
}
.FeatureList h1::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 28px;
  background: #2E4BF2;
  left: -16px;
  top: 3px;
}

.FeatureList .Description {
  margin-top: 12px !important; 
}

.borderContainer {
  border-left: #E4E8EC 1px solid;
  border-right: #E4E8EC 1px solid;
  max-width: 1143px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
/* ======WebsiteFeatures Css End======== */



/* ======WebsitePricing Css Start======== */
.PricingTablemainContainer {
  background: #F8F8F9;
  box-shadow: 0px 0px 17px #00000017;
  margin-bottom: 40px;
}

.PricingTablemainContainer:last-child {
  margin-bottom: 0px;
}

.PricingTablemainContainer .MuiTableContainer-root {
  width: 100%;
  overflow-x: visible;
  overflow-x: initial;
}

.PricingTablemainContainer .pricingTitle{

}

.PricingTablemainContainer .pricingTitle h1 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: #0B132B;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
  padding-bottom: 13px;
}
.PricingTablemainContainer .MuiTableCell-root{
  padding: 0 !important;
}

.PricingTablemainContainer .pricingTitleBottomBody {
  text-align: center;
}

.PricingTablemainContainer .pricingTitleBottomBody h2 {
  text-align: center;
  color: #0B132B;
  font-size: 40px;
  font-weight: 600;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}

.PricingTablemainContainer .pricingTitleBottomBody h3{
  text-align: center;
  color: #0B132B;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}


.PricingTablemainContainer .pricingTitleBottomBody h2 span {
  text-align: center;
  color: #0B132B;
  font-size: 23px;
  font-weight: 400;
  position: relative;
  top: -18px;
  text-align: right;
  right: -9px;
  font-family: 'Poppins', sans-serif !important;
}

.PricingTablemainContainer .pricingTitleBottomBody p {
  text-align: center;
  color: #0B132B;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 0;
  min-height: 30px;
}

.PricingTablemainContainer .priceSelectButton {
  padding: 10px;
  min-width: 160px;
  color: #2E4BF2;
  margin-top: 20px;
  width: 100%;
  border: #2E4BF2 1px solid;
  font-family: 'Poppins', sans-serif !important;
  max-width: 160px;
  height: 36px;
}

.PricingTablemainContainer .priceSelectButton:hover{
  color: #fff;
  border: #2E4BF2 1px solid;
  background:#2E4BF2;
}

.PricingTablemainContainer .blueTheme{
  background: #0590fa;
}

.PricingTablemainContainer .blueTheme .prTitleName{
  background: #0585e6;
}

.PricingTablemainContainer .blueTheme .priceSelectButton {
  background: #0590fa;
  border: #7dc5fc 1px solid;   
}

.PricingTablemainContainer .greenTheme{
  background: #2E4BF2;
}

.PricingTablemainContainer .greenTheme .prTitleName{
  background: #2E4BF2;
}

.PricingTablemainContainer .greenTheme .priceSelectButton {
  background: #2E4BF2;
  border: #2E4BF2 1px solid;
}

.PricingTablemainContainer .greyTheme{
  background: #7785cd;
}

.PricingTablemainContainer .greyTheme .prTitleName{
  background: #6e7bbd;
}

.PricingTablemainContainer .greyTheme .priceSelectButton {
  background: #7785cd;
  border: #b9c0e5 1px solid;
}

.PricingTablemainContainer .GreemButton {
  background: #2E4BF2;
  border: #2E4BF2 1px solid;
}

.PricingTablemainContainer .GreyButton {
  background: #7785cd;
  border: #b9c0e5 1px solid;
}

.PricingTablemainContainer .BlueButton {
  background: #0590fa;
  border: #7dc5fc 1px solid;   
}

.PricingTablemainContainer .PricingPackageTitleContainer{
  
}
.PricingTablemainContainer .PricingPackageTitleContainer h3 {
  font-size: 34px;
  line-height: 36px;
  margin: 20px 0;
  font-family: 'Poppins', sans-serif !important;
}

.PricingTablemainContainer .PricingPackageTitleContainerTh{
  vertical-align: baseline;
  max-width: 280px;
}

.PricingTablemainContainer .PricingPackageTitleContainer .Tax_text {
  color: #7a7f8c;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.PricingTablemainContainer .PricingPackageTitleContainer .Tax_text svg {
  color: #2E4BF2;
  font-size: 30px;
  margin-right: 6px;
}

.PricingTablemainContainer .MuiTableCell-body {
  padding: 10px 30px !important;
  font-size: 14px;
  font-weight: 600;
  border: #00000017 1px solid;
  padding-right: 20px !important;
}

.PricingTablemainContainer .MuiTableCell-root {
  border-bottom: 1px solid #00000017 !important;
  border-left: 1px solid #00000017;
}

.PricingTablemainContainer .PrcInfoIcon{
  color: #d3d4d9;
  float: right;
}

.PricingTablemainContainer .tableCheckIcon{
  color: #2E4BF2;
}

.PricingTablemainContainer .leftAling{
  float: none !important;
}

.PricingTablemainContainer .TextWithIcon{
  display: flex;
  align-items: end;
  color: #676C7B;
  font-weight: 400;
}

.PricingTablemainContainer .TextWithIcon svg{
  margin-right: 10px;
}

.PricingListTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PricingListStatus{
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiTableHead-root .MuiTableRow-head .MuiTableCell-head {
  border-top: #D2D3D8 4px solid;
  transition: 0.3s;
  padding: 20px !important;
  padding-top: 0px !important;
}

.MuiTableHead-root .MuiTableRow-head .PricingCell:hover{
  border-top:#2E4BF2 4px solid;
}

.Active {
  border-top: #2E4BF2 4px solid !important;
}


.MuiTableHead-root .MuiTableRow-head .PricingCell:hover .priceSelectButton {
   color: #fff;
    border: #2E4BF2 1px solid;
    background: #2E4BF2;
}

.Active .priceSelectButton {
  color: #fff !important;
  border: #2E4BF2 1px solid !important;
  background: #2E4BF2 !important;
  font-family: 'Poppins', sans-serif !important;
}

.PricingCell .MuiButton-label svg{
 opacity: 0;
 font-size: 18px;
  position: absolute;
  right: 31px;
}

.PricingCell.Active svg {
  opacity: 1;
  
}


.PriceSwitch {
  display: flex;
  align-items: center;
  width: 252px;
}

.PriceSwitch .SwitchStatus{
  font-size: 14px;
  color: #0B132B;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif !important;
}

.PriceSwitch .SwitchStatus span{
  display: block;
  font-size: 12px;
  color: #2E4BF2;
  font-style: italic;
  font-family: 'Poppins', sans-serif !important;
}

.PriceSwitch .withSave{
  position: relative;
  top: 13px;
}

.PriceSwitch  .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
}

.PriceSwitch .MuiSwitch-colorPrimary.Mui-checked {
  color: #2E4BF2 !important;
}

.PriceSwitch .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #2E4BF2 !important;
}

.packageD {
  max-width: 250px;
  line-height: 16px;
  color: #676C7B !important;
  font-size: 12px !important;
}

.ShowAllTableData{
  margin-top: 48px;
}

.ShowAllTableData a {
  color: #2E4BF2;
  text-transform: capitalize;
}

.ShowAllTableData a img{
  width: 18px;
}



.CustomField .MuiIconButton-label .MuiSvgIcon-root {
  color: #676C7B !important;
}


.CustomField .MuiInput-underline:before{
  display: none;
}

.CustomField .MuiInputLabel-formControl {
  top: -3px;
  left: 18px;
  position: absolute;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}

.CustomField .MuiAutocomplete-popupIndicator {
  padding: 0px;
  margin-right: 13px;
  top: 0px;
}

 .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 56px;
  padding-left: 9px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.CustomField label + .MuiInput-formControl {
  margin-top: 0px;
  margin-left: 0px;
}

.CustomField .date_time_container .MuiSvgIcon-root {
  margin-right: 0px;
}

.CustomField .date_time_container div{
  padding-bottom: 0px !important;
}

.InterestedFieldContainer  .MuiInputLabel-outlined {
  z-index: 1;
  -webkit-transform: translate(14px, 30px) scale(1);
          transform: translate(14px, 30px) scale(1);
  pointer-events: none;
}

.InterestedFieldContainer .MuiInputLabel-outlined.MuiInputLabel-shrink {
  -webkit-transform: translate(22px,-4px) scale(.75)!important;
  transform: translate(15px,4px) scale(0.75)!important;
}

@media(max-width: 990px){
  .PricingTablemainContainer .MuiTableContainer-root {
      width: 100%;
      overflow-x: auto;
  }
}

/* ======WebsitePricing Css End========== */



/* ======Why us? Css Start========== */

.FeatureList .cardIcon{
  height: 40px;
  margin-bottom: 16px;
}

.FeatureList .cardIcon img{
  height: 40px;
}

/* ======Why us? Css end========== */



/* ======Some great tips Css Start========== */

.articleCard {
  background: #fff;
  display: block;
  border: #E4E8EC 1px solid;
  cursor: pointer;
}

.articleCard:hover{
  text-decoration: none !important;
}

.articleCard .artcleImg{
  height: 144px;
  overflow: hidden;
}

.articleCard .artcleImg img{
  width: 100%;
  object-fit: cover;
}

.articleCardInfo{
  padding: 16px;
}

.articleCard h2{
  font-size: 12px;
  color: #818E94;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
}

.articleCard h1{
  font-size: 16px;
  color: #0B132B;
  line-height: 24px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif !important;
}

.articleCard .MuiButtonBase-root{
  padding: 0;
  color:#818E94;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif !important;
}

.articleCard .MuiButtonBase-root svg {
  color: #818E94;
  font-size: 16px;
  margin-left: 3px;
}

.articleCard .MuiButtonBase-root:hover{
  background-color: transparent;
}

/* ======Some great tips Css end========== */



/* ======Steper Start========== */

.SteperContainer{
  background:#F7F9FA;
  margin-bottom: 40px;
}

.SteperContainer .MuiPaper-root {
  background-color: transparent !important;
}

.SteperContainer .MuiStepIcon-root.MuiStepIcon-active {
  color: #2E4BF2;
}

.SteperContainer .MuiStepIcon-root.MuiStepIcon-completed {
  color: #2E4BF2;
}

.SteperContainer .MuiStepConnector-root {
  flex: initial;
  width: 12%;
}
.SteperContainer .MuiStepConnector-line {
  display: block;
  border-color: #E4E8EC;
}

.SteperContainer .MuiStepLabel-label {
  color: #676C7B;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
}

.SteperContainer .MuiStepIcon-root {
  color: #A4A7B0;
  display: block;
}

.PageBody{
  padding-bottom: 100px;
}

.TopSpace{
  margin-top: 40px;
}

.ButtonSection {
  padding: 15px;
  position:fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  border-top:#E4E8EC 1px solid;
}

.ButtonSection .MuiContainer-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cencelButton{
    color: #0B132B !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 0 !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.ButtonSection .MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}



/* ======Steper End========== */

.packageAcTitleContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.packageAcTitleContainer .pckgeAcTitle{
  color: #0B132B;
  font-family: 'Poppins', sans-serif !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}

.packageAcTitleContainer .ChangeButton{
  color: #2E4BF2;
  font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
}

 .Mui-expanded .ChangeButton{
  display: none;
}

.Qoutepackage .MuiPaper-root{
  border-top: #E4E8EC 1px solid;
}

.Qoutepackage .MuiAccordion-root:before{
  background-color: transparent !important;
}

.Qoutepackage .MuiAccordionSummary-root {
  padding: 0px 0;
  min-height: 65px;
  align-items: center;
}

.Qoutepackage .MuiAccordionDetails-root {
  display: flex;
  padding: 0;
}

.pckSelectStatus{
  display: inline-block;
  background-color:#EEF6F1;
  border-radius: 100px;
  color: #2E4BF2;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 15px;
  margin-left: 12px;
}


.MuiBadge-colorSecondary {
  color: #fff;
  background-color: #f50057;
  border: #fff 2px solid;
  width: 22px;
  height: 22px;
  border-radius: 100px;
  text-align: center;
  justify-content: center;
}


.accSwitch {
  position: absolute;
  right: 0;
  top: 8px;
}

.arrowButtonCont{
  display: flex;
  justify-content: space-between;
}

.arrowButtonCont a {
   color: #2E4BF2;
   font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: none;
    text-transform: initial;
    padding: 0;
}

.arrowButtonCont a:hover {
  background-color: transparent;
}

.arrowButtonCont a img {
  width: 18px;
}

.ThanksContainer{
  max-width: 660px;
  margin: 0 auto;
  text-align: center;
}

.checkIcon{
  background: #2E4BF2;
  width: 144px;
  height: 144px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 40px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.checkIcon svg{
  font-size: 80px;
}

.ThanksContainer h1 {
  font-size: 34px;
  font-weight: 700;
  color: #0B132B;
  margin: 0;
}

.ThanksContainer p{
  font-size: 20px;
  font-weight: 500;
  color: #676C7B;
  letter-spacing: 0;
  margin-bottom: 32px;
}





/* ======BLOG DETAILS START ========== */ 

.InnerPageContentSection{
  max-width: 750px;
    margin: 0 auto;
}

.TitleSmallText{
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.TitleSmallText svg{
  color: #fff;
  font-size: 9px;
}

.articleTitle {
  color: #fff !important;
  font-size: 34px !important;
  font-weight: 700 !important;
  max-width: 635px;
  font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails {
  display: flex;
  justify-content: space-between;
  border-bottom: #E4E8EC 1px solid;
  margin-bottom: 25px;
  margin-top: 25px;
  padding-bottom: 25px;
}

.autherAndSocialDetails .autherInfo {
  display: flex;
}

.autherAndSocialDetails .autherInfo .autherIcon {
  background: #EEF6F1;
  width: 44px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2E4BF2;
  margin-right: 12px;
}

.autherAndSocialDetails .autherInfo h2 {
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails .autherInfo p {
  font-size: 14px;
  font-weight: 400;
  color: #676C7B;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}

.SocialIcon a {
  display: inline-block;
  margin: 0px 5px;
}

.autherAndSocialDetails .SocialIcon a img {
  width: 44px;
}

.ArticleContentsContainer p {
  font-size: 20px !important;
  color: #0B132B !important;
  line-height: 28px !important;
  font-weight: 400 !important;
  font-family: 'Poppins', sans-serif !important;
  margin-top: 25px !important;
}

.ArticleContentsContainer .Quots {
  border-left: #005AFF 2px solid;
  font-style: italic;
  padding-left: 20px;
}

.ArticleContentsContainer .articleImg img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ArticleContentsContainer h2 {
  font-size: 20px;
  color: #0B132B;
  font-weight: 700;
  line-height: 28px;
  margin-top: 25px;
}

.ArticleContentsContainer .youtubevideo {
  margin-top: 25px;
}

.ArticleContentsContainer .articleperagraph.ArtList {
  display: flex;
  align-items: baseline;
}

.ArticleContentsContainer .articleperagraph.ArtList svg {
  font-size: 12px;
  margin-right: 12px;
}

.ArticleContentsContainer .articleImg {
  height: 400px;
  width: 100%;
  margin-top: 25px;
}

.InnerPageBanner {
  padding-top: 40px;
  padding-bottom: 20px;
}

.BottomSocial{
  border-bottom: #E4E8EC 1px solid;
  border-top: #E4E8EC 1px solid;
  padding: 20px 0;
  margin-top: 40px;
  text-align: right;
}

.SubscribeContainer{
  background:#F7F8FA;
  padding: 20px 0;
  margin-top: 80px;
}

.SubscribeContent{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SubscribeContent .Subscribeinfo{
  display: flex;
  align-items: center;
}

.SubscribeContent .Subscribeinfo h2{
  font-size: 20px;
  color: #0B132B;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
}

.SubscribeContent .Subscribeinfo p {
  font-size: 14px;
  color: #4D4D4D;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0;
  max-width: 440px;
  line-height: 22px;
}

.SubscribeContent .MailIcon{
  margin-right: 20px;
}

.SubscribForm{
  width: 50%;
}

.SubscribForm .ButtonGreenFilled {
  min-width: 130px;
  border-radius: 0 4px 4px 0px !important;
  height: 50px;
  padding: 0 10px !important;
}

.GetStartForm .GetStartedField  .MuiOutlinedInput-input {
  padding: 15.5px 14px;
  color: #fff;
  background: #2E4BF2;
}

.GetStartForm .GetStartedField .MuiOutlinedInput-notchedOutline {
  border-color: #FFFFFF !important;
}
.SubscribForm .GetStartForm {
  padding-top: 0 !important;
}
/* ======BLOG DETAILS END ========== */ 



.carousel-images .MuiTypography-root{
  font-size: 60px !important;
  letter-spacing: 0 !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 700 !important;
  line-height: 76px !important;
}

.control-dots{
  display: none;
}

.carousel-status{
  display: none;
}

.MuiButton-label img{
  margin-left: 6px;
}

.ButtonSectionInner {
  margin: 0 auto;
  max-width: 1100px;
}

.ButtonSectionInnerCol{
  display: flex;
  justify-content: space-between;
  max-width: 825px;
}

.FormBodyWrapper{
  max-width: 820px;
}

.BackButtonContainer{
  margin-bottom: 40px;
}

.BackButtonContainer .MuiButtonBase-root {
  background: #fff;
  border: #2E4BF2 1px solid;
  color: #2E4BF2;
  width: 96px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.BackButtonContainer .MuiButtonBase-root img{
  margin-right: 5px;
  margin-left: 0 !important;
}

.TabbleSectionpage{
  padding-top: 40px;
  padding-bottom: 80px;
}

.PricingTableContainers .LeftText {
  text-align: left !important;
}

.HomePageContainer .HideHome{
  display: none;
}

.blogSectioTitle{
  font-size: 24px;
  color: #0B132B;
  text-align: center;
  max-width: 540px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif !important;
}

.SocialIconContainer{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SocialIconContainer a {
  display: flex !important;
  align-items: center !important;
  background: #1877F2 !important;
  border-radius: 100px !important;
  padding: 11px 13px !important;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  padding-right: 19px !IMPORTANT;
}

.SocialIconContainer a img {
  width: 40px;
  margin-right: 10px;
}


.FooterContactInfo {
  margin-left: 27px;
}

.FooterContactInfo p{
  font-size: 16px;
  color: #0B132B;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  text-decoration: none;
  margin: 0;
}


.FooterContactInfo p a{
  font-size: 16px;
  color: #0B132B;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  text-decoration: none;
}

.FooterContactInfo p a:hover{
  color: #2E4BF2;
}

.SocialIconContainerFooter{}

.widthFull{
  width: 100%;
}

.FooterBottomLeft{
  display: flex;
  align-items: center;

}

.FooterBottomContainer {
  background: #FCFCFC;
  padding: 0px 25px;
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: #2E4BF2 4px solid;
}

.FooterBottomContainer .FooterBottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FooterBottom span a img{
  width: 137px;
  margin-left: 5px;
  position: relative;
  top: 4px;
}

.FooterBottomLink a{
  font-size: 14px;
  color: #676C7B;
  text-decoration: none;
  display: block;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif !important;
}

.FooterBottomLink a:hover{
  color: #2E4BF2;
}

.inprogressChip {
  background: #A4A7B0;
  color: #FFFFFF;
  font-size: 14px;
  width: 110px;
  height: 24px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.MuiInput-underline:after{
  display: none;
}

.FooterSocial a {
  display: flex !important;
  align-items: center !important;
  border-radius: 100px !important;
  padding: 11px 13px !important;
  color: #0B132B;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.FooterSocial a img{
  width: 20px;
  margin-right: 10px;
}





@media (max-width: 1360px){
  .masjidImg {
    position: relative;
    right: 0;
    bottom: -57px;
    margin: 0 auto;
    max-width: 360px;
  }
  .masjidImg img {
    width: 100%;
}
  
}



@media (max-width: 990px){
  .BookingFormHome {
    display: block;
}

  .BookingFormHome .MuiFormControl-root {
    flex: auto;
    width: 48%;
    margin-bottom: 16px;
  }
  .BookingFormHome .ButtonWhiteFilled {
    width: 48%;
}



}

@media (max-width: 845px){
  .BookingFormHome .MuiFormControl-root {
    width: 47%;
}

.BookingFormHome .ButtonWhiteFilled {
  width: 47%;
}
}

@media (max-width: 768px){
  .TitleLarge {
    font-size: 38px !important;
    line-height: 56px !important;
}

.TitleSmall {
  font-size: 38px !important;
  line-height: 42px !important;
}

.GetStartForm {
  display: block;
}

.GetStartedField {
  width: 100%;
}
.GetStartForm .GetStartedField .MuiInputBase-formControl {
  border-radius: 4px;
}

.GetStartForm .GetStartedButton {
  border-radius: 4px;
  width: 100%;
  margin-top: 20px;
  padding: 11px;
}

.FullWidthGridContainer {
  display: block;
  padding: 16px;
}

.websiteImg {
  width: 96%;
  margin-right: 0;
}

.WebsiteContents {
  width: 100%;
}

.articleCard .artcleImg {
  height: auto;
  overflow: visible;
}

}

@media (max-width: 580px){
  .BookingFormHome .MuiFormControl-root {
    width: 100%;
}

.BookingFormHome .MuiFormControl-root {
  margin: 16px 0px;
}

.BookingFormHome .ButtonWhiteFilled {
  width: 100%;
  margin: 16px 0;
}

.FeatureList {
  min-height: auto;
}


}

@media (max-width: 460px){
  
.MuiBadge-root{
  width: 100%;
}

.MuiBadge-root button{
  width: 100%;
}

}

.singletitle{
  margin-bottom: 40px !important;
}

.WebsiteMainContainer .websiteImgs img{
  width: 100%;
}

.WebsiteMainContainer .BuildProjectBox {
  padding: 0;
}
.WebsiteMainContainer .WebsiteBox .WebsiteBoxInfoSection h1 {
  font-size: 16px;
  font-weight: 700;
  color: #0B132B;
  line-height: 36px;
  margin:0 ;
  font-family: 'Poppins', sans-serif !important;
}

.WebsiteBoxInfoSection{
 background: #FFF;
 padding: 16px;
 text-align: center;
}

.WebsiteBoxInfoSection .MuiButton-text{
  color: #2E4BF2;
  padding: 0 !important;
}

.PageTitlepattren{
  background:#2E4BF2 url(/static/media/RepeatGrid.600cc8ee.svg);
  height: 470px;
  width: 100%;
  position: absolute;
  top: 115px;
}

.GetQuoteFormContainer{
  padding: 40px;
  position: relative;
  
}

.BGColorGrey{
  background: #FCFCFC;
}

.GetQuoteFormContainer h1{
  color: #FFFFFF;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.GetQuoteForm{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000017;
  border-radius: 4px;
  opacity: 1;
  padding: 50px 95px;
  max-width: 730px;
  margin: 0 auto;
  margin-top: 40px;
}

.GetQuoteFormTopText{
  color: #0B132B;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #2E4BF2 !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: #f44336!important;
}

.MuiFormLabel-root.Mui-focused  {
    color:#2E4BF2 !important;
}

.InnerPageContainer{
  position: relative;
}

.EmbedAnythingContents .MuiCollapse-container {
  width: 100% !important;
  margin-left: 0 !important;
  position: relative !important;
  top: 0 !important;
  background-color: transparent !important;
}

.EmbedAnythingContents .MuiAccordion-rounded {
  border-radius: 0;
  padding: 0 15px;
}

.CarCount{
  display: flex;
  justify-content: end;
  color: #A4A7B0;
  font-size: 12px;
}

.FooterBottom .MuiTypography-displayBlock {
  display: flex;
  align-items: center;
}

.textEditorContainer .editor_tool {
  display: none !important;
}

.textEditorContainer .text-editor {
  border: 1px solid #e4e8ec;
  border-radius: 4px;
}

.CustomField .MuiInput-root {
  border: 1px solid #e4e8ec;
  border-radius: 4px;
  padding: 0;
  min-height: 56px;
}

.CustomField .MuiFormControl-root {
  margin: 0 !important;
}

.InquiryField .MuiNativeSelect-select.MuiNativeSelect-select{
  padding-right: 24px;
  padding-left: 19px !important;
}

.InquiryField .MuiNativeSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 11px !important;
  position: absolute;
  pointer-events: none;
}

.carousel-images{
  text-decoration: underline;
}

.HomePageContainer .TitleGreen.GreenText{
  color: #2E4BF2 !important;
}

.FooterMainContainer{
  border-top: #e8e8eb  1px solid;
}

.text-editor .text-box p {
  font-family: 'Poppins', sans-serif !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input{
  display: none;
}

.MuiPopover-paper .MuiMenu-list .MuiListItem-button {
  padding: 9px 30px !important;
}

.SelectDropdownGroup .MuiInputLabel-formControl {
  top: 7px;
}

.MuiAutocomplete-listbox {
  margin: 0;
  padding: 8px 0px;
  overflow: auto;
  list-style: none;
  max-height: 400px;
  border: #e5e5e5 1px solid;
}

.MuiPopover-paper {
  margin-left: 0px!important;
  width: 728px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #e4e8ec !important;
}

.input_label span{
  color:#f44336 ;
}



.textEditorContainer.error .text-editor{
  border: 1px solid #f44336;
}

.textEditorContainer.error .text-editor .text-box p {
  color: #f44336;
  opacity: 1;
}

.MuiOutlinedInput-input {
  padding: 18.5px 14px !important;
}


.EmailAlert {
  position: fixed;
  top: 0px !important;
  left: 37% !important;
  z-index: 99999 !important;
  min-width: 26% !important;
}

.EmailAlert .MuiAlert-standardSuccess{
  background-color: #33B651 !important;
  color: #fff !important;
}

.EmailAlert .MuiIconButton-label .MuiSvgIcon-root {
  color: #fff !important;
}

.MuiAlert-standardSuccess .MuiAlert-icon {
  color: #fff !important;
}
.SubscriptionMainContiner{
    background:#F7F8FA;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SubscriptionFieldContainer{
    display: flex;
    flex: 1 1;
  }
  
  .SubscriptionFieldContainer .SubscriptionField{
    width: 100%;
  }
  .SubscriptionFieldContainer .SubscriptionField .input_container .MuiInputBase-input, .input_label{
    font-size: 16px;
  }

  .SubscriptionFieldContainer .SubscriptionField .input_container .MuiOutlinedInput-input {
    height: 48px;
    padding: 0 16px;
    width: 100%;
    background: #fff;
    border: #ccc 1px solid;
    border-radius: 0px;
}

.SubscriptionFieldContainer .SubscriptionField .MuiOutlinedInput-root:hover{
    outline: none;
    border: none;
}

.SubscriptionFieldContainer .SubscriptionField .MuiOutlinedInput-notchedOutline{
    display: none;
}
  
  .SubscriptionFieldContainer .SubscriptionButton .MuiButtonBase-root {
    border-radius:  0 !important;
    height: 50px !important;
    margin: 0 !important;
    background-color:#7266FB;
  }

.SubscriptionTextContainer {
    display: flex;
    align-items: center;
    flex: 1 1;
}

  .SubscriptionMainContiner .SubscriptionTextContainer .SubscriptionEmailIcon{
    width:50px;
    height: 50px;
    margin-right: 20px;
  }

  .SubscriptionTextCol h2{
      font-size: 20px;
      color:#2D2D2D;
      line-height: 24px;
      margin: 0;
  }

  .SubscriptionTextCol p{
    font-size: 14px;
    color:#2D2D2D;
    line-height: 20px;
    margin: 0;
    padding-top: 5px;
}

.GetQuoteForm .input_container .MuiOutlinedInput-input {
  height: 56px;
  padding: 0 16px;
  width: 100%;
  background: #fff;
  border: #e4e8ec 1px solid;
  border-radius: 4px;
  
}

.GetQuoteForm .input_container .MuiOutlinedInput-input:focus{
  border: #2e4bf2 1px solid;
}

.PhoneFieldManContainer .react-tel-input .form-control:focus{
  border: #2e4bf2 1px solid;
}

.GetQuoteForm .input_container .MuiOutlinedInput-notchedOutline {
  display: none;
}

.BlogListingMainContainer {
    padding-top: 24px;
}

.BlogListingCardLarge{
    position: relative;
    border: #E4E8EC 1px solid;
}

.BlogListingCardLarge .BlogListingCardLargeImg{
    max-height: 420px;
    overflow: hidden;
}

.BlogListingCardLarge .BlogListingCardLargeImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BlogListingMainContainer{
    max-width: 1140px;
    margin: 0 auto;
}

.BlogListingMainContainer .BlogListingCardLargeInfo{
    padding: 32px;
}


.BlogListingMainContainer .BlogListingCardLargeInfo .BlogListingCardLargeInfoPost {
    color: #818E94;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}
.BlogListingMainContainer .BlogListingCardLargeInfo .BlogListingCardLargeInfoPost span{
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #D3D8DA;
    border-radius: 100px;
    margin: 0 5px;
}

.BlogListingMainContainer .BlogListingCardLargeInfo h1{
    font-size: 38px;
    color: #39337E;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.BlogListingMainContainer .BlogListingCardLargeInfo .BlogReadMoreButton{
    display: flex;
    color: #818E94;
    font-size: 14px;
    text-transform: uppercase;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.BlogListingMainContainer .BlogListingCardLargeInfo .BlogReadMoreButton svg{
    font-size: 13px;
    margin-left: 5px;

}

.BlogListingSection{
    margin-top: 12px;
    margin-bottom: 30px;
}

.BlogListingSection a {
    text-decoration: none;
}

.BlogListingSection a:hover {
    text-decoration: none;
}

.BlogListingCardSmall{
    position: relative;
    border: #E4E8EC 1px solid;
    cursor: pointer;
}
.BlogListingCardSmall a:hover{
    text-decoration: none !important;
}

.BlogListingCardSmall .BlogListingCardSmallImg{
    max-height: 420px;
    overflow: hidden;
}

.BlogListingCardSmall .BlogListingCardSmallImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.BlogListingCardSmall .BlogListingCardSmallInfo{
    padding: 20px;
}


.BlogListingCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost {
    color: #818E94;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}
.BlogListingCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost span{
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #D3D8DA;
    border-radius: 100px;
    margin: 0 5px;
}

.BlogListingCardSmall .BlogListingCardSmallInfo h1{
    font-size: 20px;
    color: #39337E;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    line-height: 28px;
    text-decoration: none;
}

.BlogListingCardSmall .BlogListingCardSmallInfo .BlogSmallCardReadMoreButton{
    display: flex;
    color: #818E94;
    font-size: 14px;
    text-transform: uppercase;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    transition: 0.3s;
}

.BlogListingCardSmall .BlogListingCardSmallInfo .BlogSmallCardReadMoreButton svg{
    font-size: 13px;
    margin-left: 5px;

}

.BlogListingCardSmall{
    transition: 0.3s;
}

.BlogListingCardSmall:hover {
    box-shadow: 0px 12px 17px #0000000F;
}

.BlogListingCardSmall:hover .BlogSmallCardReadMoreButton{
    color:#39337E;
    font-weight: 600;
}

.LoadmoreButtonContainer{
    background:#E6FAF1;
    color:#05CE78;
    cursor: pointer;
    padding: 15px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
}

.TabMainContainer .MuiTabs-flexContainer {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
    border-bottom: #E4E8EC 1px solid;
}

.TabMainContainer .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator{
    background-color: #7266FB !important;
}

.TabMainContainer .MuiButtonBase-root .MuiTab-wrapper{
    text-align: left !important;
    font-weight: 600;
}




.BlogListingRecentCardSmall .BlogListingCardSmallInfo{
    padding: 20px;
}

.BlogListingRecentCardSmall a:hover{
    text-decoration: none;
}


.BlogListingRecentCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost {
    color: #818E94;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}
.BlogListingRecentCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost span{
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #D3D8DA;
    border-radius: 100px;
    margin: 0 5px;
}

.BlogListingRecentCardSmall .BlogListingCardSmallInfo h1{
    font-size: 20px;
    color: #39337E;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    line-height: 28px;
    text-decoration: none;
}

.BlogListingRecentCardSmall{
    transition: 0.3s;
    cursor: pointer;
}

.BlogListingRecentCardSmall:hover {
    box-shadow: 0px 12px 17px #0000000F;
}

.BlogListingRecentCardSmall:hover h1{
    color: #7266FB;
}

.TabMainContainer .MuiTabs-root{
    position: relative;
    left: 20px;
    margin-bottom: 15px;
}

.BlogListingRecentCardSmall a{
    text-decoration: none;
}




.InnerPageContentSection{
    max-width: 750px;
    margin: 0 auto;
}
.autherAndSocialDetails{
	display: flex;
	justify-content: space-between;
	border-bottom: #E4E8EC 1px solid;
	margin-bottom: 25px;
	margin-top: 25px;
	padding-bottom: 25px;
}

.autherAndSocialDetails .autherInfo{
	display: flex;
}

.autherAndSocialDetails .autherInfo .autherIcon{
	background: #EEF6F1;
	width: 44px;
	height: 44px;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #2E4BF2;
	margin-right: 12px;
}

.autherAndSocialDetails .autherInfo h2{
	font-size: 14px;
	font-weight: 400;
	color: #444444;
	margin: 0;
	font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails .autherInfo p{
	font-size: 14px;
	font-weight: 400;
	color: #676C7B;
	margin: 0;
	font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails .SocialIcon a img {
    width: 44px;
    margin: 0 5px;
    cursor: pointer;
}

.ArticleContentsContainer p{
	font-size: 20px !important;
	color: #0B132B !important;
	line-height: 28px !important;
	font-weight: 400 !important;
	font-family: 'Poppins', sans-serif !important;
	margin-top: 25px !important;

}

.ArticleContentsContainer .Quots{
	border-left:#005AFF 2px solid;
	font-style: italic;
	padding-left: 20px;
}


.ArticleContentsContainer .articleImg{
	height: 400px;
	width: 100%;
	margin-top: 25px;
}

.ArticleContentsContainer .articleImg img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.ArticleContentsContainer h2{
	font-size:20px;
	color: #0B132B;
	font-weight: 700;
	line-height: 28px;
	margin-top: 25px;
}

.ArticleContentsContainer .youtubevideo{
	margin-top: 25px;
}

.ArticleContentsContainer .articleperagraph.ArtList {
    display: flex;
    align-items: baseline;
}

.ArticleContentsContainer .articleperagraph.ArtList svg{
	font-size: 12px;
	margin-right: 12px;
}

.articleTitle{
    font-size: 34px;
    font-weight: 700;
    color: #4D4D4D;
    font-family: 'Poppins', sans-serif !important;
    line-height: 40px;
}

.BlogDetailsBanner{
    max-width: 1110px;
    margin: 0 auto;
}

.BlogDetailsBanner img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}


/* Article Details CSS END */


