@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.BlogListingMainContainer {
    padding-top: 24px;
}

.BlogListingCardLarge{
    position: relative;
    border: #E4E8EC 1px solid;
}

.BlogListingCardLarge .BlogListingCardLargeImg{
    max-height: 420px;
    overflow: hidden;
}

.BlogListingCardLarge .BlogListingCardLargeImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BlogListingMainContainer{
    max-width: 1140px;
    margin: 0 auto;
}

.BlogListingMainContainer .BlogListingCardLargeInfo{
    padding: 32px;
}


.BlogListingMainContainer .BlogListingCardLargeInfo .BlogListingCardLargeInfoPost {
    color: #818E94;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}
.BlogListingMainContainer .BlogListingCardLargeInfo .BlogListingCardLargeInfoPost span{
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #D3D8DA;
    border-radius: 100px;
    margin: 0 5px;
}

.BlogListingMainContainer .BlogListingCardLargeInfo h1{
    font-size: 38px;
    color: #39337E;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.BlogListingMainContainer .BlogListingCardLargeInfo .BlogReadMoreButton{
    display: flex;
    color: #818E94;
    font-size: 14px;
    text-transform: uppercase;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.BlogListingMainContainer .BlogListingCardLargeInfo .BlogReadMoreButton svg{
    font-size: 13px;
    margin-left: 5px;

}

.BlogListingSection{
    margin-top: 12px;
    margin-bottom: 30px;
}

.BlogListingSection a {
    text-decoration: none;
}

.BlogListingSection a:hover {
    text-decoration: none;
}

.BlogListingCardSmall{
    position: relative;
    border: #E4E8EC 1px solid;
    cursor: pointer;
}
.BlogListingCardSmall a:hover{
    text-decoration: none !important;
}

.BlogListingCardSmall .BlogListingCardSmallImg{
    max-height: 420px;
    overflow: hidden;
}

.BlogListingCardSmall .BlogListingCardSmallImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.BlogListingCardSmall .BlogListingCardSmallInfo{
    padding: 20px;
}


.BlogListingCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost {
    color: #818E94;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}
.BlogListingCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost span{
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #D3D8DA;
    border-radius: 100px;
    margin: 0 5px;
}

.BlogListingCardSmall .BlogListingCardSmallInfo h1{
    font-size: 20px;
    color: #39337E;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    line-height: 28px;
    text-decoration: none;
}

.BlogListingCardSmall .BlogListingCardSmallInfo .BlogSmallCardReadMoreButton{
    display: flex;
    color: #818E94;
    font-size: 14px;
    text-transform: uppercase;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    transition: 0.3s;
}

.BlogListingCardSmall .BlogListingCardSmallInfo .BlogSmallCardReadMoreButton svg{
    font-size: 13px;
    margin-left: 5px;

}

.BlogListingCardSmall{
    transition: 0.3s;
}

.BlogListingCardSmall:hover {
    box-shadow: 0px 12px 17px #0000000F;
}

.BlogListingCardSmall:hover .BlogSmallCardReadMoreButton{
    color:#39337E;
    font-weight: 600;
}

.LoadmoreButtonContainer{
    background:#E6FAF1;
    color:#05CE78;
    cursor: pointer;
    padding: 15px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
}

.TabMainContainer .MuiTabs-flexContainer {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
    border-bottom: #E4E8EC 1px solid;
}

.TabMainContainer .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator{
    background-color: #7266FB !important;
}

.TabMainContainer .MuiButtonBase-root .MuiTab-wrapper{
    text-align: left !important;
    font-weight: 600;
}




.BlogListingRecentCardSmall .BlogListingCardSmallInfo{
    padding: 20px;
}

.BlogListingRecentCardSmall a:hover{
    text-decoration: none;
}


.BlogListingRecentCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost {
    color: #818E94;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}
.BlogListingRecentCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost span{
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #D3D8DA;
    border-radius: 100px;
    margin: 0 5px;
}

.BlogListingRecentCardSmall .BlogListingCardSmallInfo h1{
    font-size: 20px;
    color: #39337E;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    line-height: 28px;
    text-decoration: none;
}

.BlogListingRecentCardSmall{
    transition: 0.3s;
    cursor: pointer;
}

.BlogListingRecentCardSmall:hover {
    box-shadow: 0px 12px 17px #0000000F;
}

.BlogListingRecentCardSmall:hover h1{
    color: #7266FB;
}

.TabMainContainer .MuiTabs-root{
    position: relative;
    left: 20px;
    margin-bottom: 15px;
}

.BlogListingRecentCardSmall a{
    text-decoration: none;
}




.InnerPageContentSection{
    max-width: 750px;
    margin: 0 auto;
}
.autherAndSocialDetails{
	display: flex;
	justify-content: space-between;
	border-bottom: #E4E8EC 1px solid;
	margin-bottom: 25px;
	margin-top: 25px;
	padding-bottom: 25px;
}

.autherAndSocialDetails .autherInfo{
	display: flex;
}

.autherAndSocialDetails .autherInfo .autherIcon{
	background: #EEF6F1;
	width: 44px;
	height: 44px;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #2E4BF2;
	margin-right: 12px;
}

.autherAndSocialDetails .autherInfo h2{
	font-size: 14px;
	font-weight: 400;
	color: #444444;
	margin: 0;
	font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails .autherInfo p{
	font-size: 14px;
	font-weight: 400;
	color: #676C7B;
	margin: 0;
	font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails .SocialIcon a img {
    width: 44px;
    margin: 0 5px;
    cursor: pointer;
}

.ArticleContentsContainer p{
	font-size: 20px !important;
	color: #0B132B !important;
	line-height: 28px !important;
	font-weight: 400 !important;
	font-family: 'Poppins', sans-serif !important;
	margin-top: 25px !important;

}

.ArticleContentsContainer .Quots{
	border-left:#005AFF 2px solid;
	font-style: italic;
	padding-left: 20px;
}


.ArticleContentsContainer .articleImg{
	height: 400px;
	width: 100%;
	margin-top: 25px;
}

.ArticleContentsContainer .articleImg img{
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.ArticleContentsContainer h2{
	font-size:20px;
	color: #0B132B;
	font-weight: 700;
	line-height: 28px;
	margin-top: 25px;
}

.ArticleContentsContainer .youtubevideo{
	margin-top: 25px;
}

.ArticleContentsContainer .articleperagraph.ArtList {
    display: flex;
    align-items: baseline;
}

.ArticleContentsContainer .articleperagraph.ArtList svg{
	font-size: 12px;
	margin-right: 12px;
}

.articleTitle{
    font-size: 34px;
    font-weight: 700;
    color: #4D4D4D;
    font-family: 'Poppins', sans-serif !important;
    line-height: 40px;
}

.BlogDetailsBanner{
    max-width: 1110px;
    margin: 0 auto;
}

.BlogDetailsBanner img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}


/* Article Details CSS END */

