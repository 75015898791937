.TopbarContainer{
    background: #000000;
    height: 34px;
    display: flex;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.HeaderOneMainContainer {
    position: relative  !important;

}

.menu-item a{ font-family: 'Poppins', sans-serif !important;}
.MuiButton-label{font-family: 'Poppins', sans-serif !important;}


.HeaderFixed .HeaderOneMainContainer {
    position: fixed !important

}

.TopbarContainer .TopbarContainerInner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TopbarContainer .TopbarContainerInner .HeaderTopLeftCol{
    display: flex;
    align-items: center;
}

.TopbarContainer .TopbarContainerInner .HeaderTopLeftCol a{
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px;
    margin-right: 15px;
}

.TopbarContainer .TopbarContainerInner .HeaderTopLeftCol a span{
    font-weight: 600;
}

.TopbarContainer .TopbarContainerInner .HeaderTopLeftCol svg{
    color: #fff;
    margin-right: 10px;
    font-size: 18px;
}


.sectionSpace{
    padding-top: 145px;
}

.HeaderOnesiteLogo a{
    max-width: 308px !important;
}